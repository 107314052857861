/**
 *
 * Apply Here your Custom CSS
 *
*/

body #root{
    height:100%
}

.float-right {
  float: right;
}

.input-range__slider {
    appearance: none;
    background: #3f51b5;
    border: 1px solid #3f51b5;
    border-radius: 100%;
    cursor: pointer;
    display: block;
    height: 1rem;
    margin-left: -0.5rem;
    margin-top: -0.65rem;
    outline: none;
    position: absolute;
    top: 50%;
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
    width: 1rem; }
    .input-range__slider:active {
      transform: scale(1.3); }
    .input-range__slider:focus {
      box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2); }
    .input-range--disabled .input-range__slider {
      background: #cccccc;
      border: 1px solid #cccccc;
      box-shadow: none;
      transform: none; }
  
  .input-range__slider-container {
    transition: left 0.3s ease-out; }
  
  .input-range__label {
    color: #aaaaaa;
    font-family: "Helvetica Neue", san-serif;
    font-size: 1.2rem;
    transform: translateZ(0);
    white-space: nowrap;
  }
  
  .input-range__label--min,
  .input-range__label--max {
    display: none;
    bottom: -1.4rem;
    position: absolute; }
  
  .input-range__label--min {
    left: 0; }
  
  .input-range__label--max {
    right: 0; }
  
  .input-range__label--value {
    position: absolute;
    top: -2.5rem; }
  
  .input-range__label-container {
    left: -50%;
    position: relative; }
    .input-range__label--max .input-range__label-container {
      left: 50%; }
  
  .input-range__track {
    background: #eeeeee;
    border-radius: 0.3rem;
    cursor: pointer;
    display: block;
    height: 0.3rem;
    position: relative;
    transition: left 0.3s ease-out, width 0.3s ease-out; }
    .input-range--disabled .input-range__track {
      background: #eeeeee; }
  
  .input-range__track--background {
    left: 0;
    margin-top: 0.5rem;
    position: absolute;
    right: 0;
    top: 50%; }
  
  .input-range__track--active {
    background: #3f51b5; }
  
  .input-range {
    height: 2.5rem;
    position: relative;
    width: 100%;
}
  
  /*# sourceMappingURL=index.css.map */



  .questionaly hr {
    margin-top: 5px;
    margin-bottom: 5px;
    border: 0;
    border-top: 1px solid #eeeeee;
  }

  /* .question-body {
    margin-top: 15px;
  } */



.react-datepicker__input-container input {
  border: 1px solid rgb(235, 235, 235) !important;
}

h4.qh4 {
  font-weight: 600;
}

p.qp {
  color: #303641;
}

p.ansph4 {
  font-weight: 600;
  color: #303641;
  background: #E9E9E9;
  width: fit-content;
  border-radius: 5px;
}

.answerWrapper {
  width: 100%;
  padding: 20px;
  .answerItem {
    border-left: 5px solid #666;
    padding-left: 10px;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.answerButtons {
  width: fit-content;
  height: fit-content;
  position: absolute;
  right: 20px;
  bottom: 20px;
  display: flex;
  button {
    margin: 0 5px;
    &:last-child {
      margin-right: 0;
    }
  }
  .flgs {
    display: flex;
    padding: 5px 15px;
    background-color: #F9D235;
    color: #333;
    border-radius: 4px;
    .radioGroup {
      margin: 0 5px;
      label {
        margin: 0 0 0 3px;
      }
      input {
        margin: 0 3px 0 0;
        position: relative;
        top: 2px;
      }
    }
  }
}

/* add */

ul.chat-panel {
  list-style: none;
  margin: 0;
  padding: 0 10px;
}

ul.chat-panel li {
  margin: 10px 0;
}

ul.chat-panel .user p {
  margin: 3px 0 3px 0;
  padding: 10px;
  border-radius: 6px;
  background: rgba(0,0,0,0.05);
  color: rgba(0, 0, 0, 1);
  -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0 4px 8px;
  -moz-box-shadow: rgba(0,0,0,0.1) 0 4px 8px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 8px;
  .date {
    color: rgba(0, 0, 0, 0.5);
  }
}

ul.chat-panel .user {
  width: fit-content;
  max-width: 80%;
}

ul.chat-panel .admin {
  display: flow-root;
  width: fit-content;
  max-width: 80%;
  margin: 10px 0 10px auto;
}

ul.chat-panel .admin p {
  margin: 0;
  margin: 3px 0 3px 0;
  padding: 10px;
  background-image: linear-gradient(to top right, #3470df 0%, #24bd8b 100%);
  border-radius: 6px;
  color: #FFF;
  -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0 4px 8px;
  -moz-box-shadow: rgba(0,0,0,0.1) 0 4px 8px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 8px;
  .date {
    color: rgba(255, 255, 255, 0.8);
  }
}

ul.chat-panel .admin small.name {
  font-size: 1.1em;
  font-weight: bold;
  margin: 10px 0 3px 0;
  color: #999;
}

ul.chat-panel .admin small.date {
  display: block;
  width: 100%;
}


ul.chat-panel .user small.name {
  font-size: 1.1em;
  font-weight: bold;
  margin: 10px 0 3px 0;
  color: #999;
}

ul.chat-panel .user small.date {
  display: block;
  width: 100%;
}

ul.chat-panel .user small.isread {
  display: block;
  float: left;
}

.timerangestartend {
  width: 100%;
}

.timerangestartend .end {
  float: right;
}

/* React Modal Animation */

.overlay-base {
  padding: 1rem;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0);
  opacity: 0;
  transition-property: background-color, opacity;
  transition-duration: 500ms;
  transition-timing-function: ease-in-out;
  outline: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.overlay-after {
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 1;
}

.overlay-before {
  background-color: rgba(0, 0, 0, 0);
  opacity: 0;
}

.content-base {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  margin: 0 auto;
  border: 0;
  outline: 0;
  display: flex;
  justify-content: center;
  height: 0%;
  width: 0%;
  background-color: transparent;
  transition-property: background-color, width, height;
  transition-duration: 500ms;
  transition-timing-function: ease-in-out;
}

.content-after {
  width: 70%;
  height: fit-content;
  max-height: 90%;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 10px;
  // overflow: hidden;
  // position: relative;
  .label.statusFlg {
    position: absolute;
    right: 20px;
    top: 20px;
  }
}

.content-information-after {
  width: fit-content;
  height: fit-content;
  max-height: 90%;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 10px;
  overflow: auto;
  padding: 10px 20px;
}

.content-information-after p.content {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 1em;
  color: #303641;
  white-space: pre-wrap;
}


.content-after-2 {
  width: auto;
  height: auto;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 10px;
  overflow: hidden;
}

@media (max-width: 991px) {
  .content-after {
    width: 95%;
  }
}

.content-before {
  width: 0%;
  height: 0%;
  background-color: transparent;
}

.tabs-vertical > li.active > a {
  display: block;
  position: relative;
  background-color: #FFFFFF;
  color: #373e4a;
  box-shadow: 0 5px 5px rgba(50,50,50,0.1);
}

ul.questionlist {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul.questionlist li {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
  width: 100%;
  margin: 0 0 3px 0;
  padding: 10px 15px;
  background: #fafafa;
  cursor: pointer;
}

ul.questionlist li:hover {
  background: #f1f1f1;
}

ul.questionlist li p{
  float: left;
  padding-top: 3px;
  margin: 0;
}

ul.questionlist li div{
  float: right;
}

div.createArea {
  float: left;
  width: 60%;
  overflow: auto;
  overflow-x: hidden;
  padding: 20px;
  background-color: white;
}

div.menuArea {
  float: right;
  width: 40%;
  /* overflow: scroll; */
  overflow-x: hidden;
  padding: 20px;
  background-color: white;
}

div.menuArea .menusection {
  margin-bottom: 30px;
}

.menusection h4 {
  margin: 5px 0 5px 0;
  color: white;
  background: #4DD3C7;
  padding: 10px;
  border-radius: 5px;
  font-weight: bold;
}

.menusection p {
  margin: 10px;
}

.menusection ul.sendSchedules {
  margin: 0;
  padding: 0;
  display: block;
  overflow: hidden;
}

.menusection ul.sendSchedules li {
  list-style: none;
  margin: 0;
  padding: 0;
  height: 40px;
}

.question-header {
  float: right;
}

.dropZoneInputWrapper {
  display: flex;
}

.dropZoneInputWrapper img {
  width: auto;
  height: 150px;
}

.dropZoneInputWrapper .dropZoneInput {
  cursor: pointer;
  position: relative;
  height: 150px;
  border: 2px dashed #e2e2e2;
  background-color: #F5F5F5;
  border-radius: 10px;
  margin-bottom: 30px;
}    

.dropZoneInputWrapper .dropZoneInput p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #cccccc;
  width: fit-content;
}

.dropZoneInputWrapper section.imagesection {
  margin-left: 10px;
}





.leftspace {
  margin-left: 20px;
}

.rightspace {
  margin-right: 20px;
}

.btn {
  background: var(--unnamed-color-c7c7c7) 0% 0% no-repeat padding-box;
  background: #C7C7C7 0% 0% no-repeat padding-box;
  box-shadow: 0px 8px 15px #C7C7C780;
  border: none;
  border-radius: 0.25em;
  opacity: 1;
  color: #FFF;
  padding: 5px 15px;
  transition: 0.3s;
  width: fit-content;
  
  &:hover {
    outline: none;
    transform: translate(0, 2px);
  }

  &.btn-red {
      background: #FC6969 0% 0% no-repeat padding-box;
      box-shadow: 0px 8px 15px #FC696980;
      border: none;
      &:hover {
          outline: none;
          box-shadow: 0px 4px 8px #FC696980;
          color: #FFF;
          text-decoration: none;
      }
  }

  &.btn-blue {
      background: #49B2E0 0% 0% no-repeat padding-box;
      box-shadow: 0px 8px 15px #49B2E080;
      border: none;
      &:hover {
          outline: none;
          box-shadow: 0px 4px 8px #49B2E080;
          color: #FFF;
          text-decoration: none;
      }
  }

  &.btn-green {
      background: #4DD3C7 0% 0% no-repeat padding-box;
      box-shadow: 0px 8px 15px #4DD3C780;
      border: none;
      &:hover {
          outline: none;
          box-shadow: 0px 4px 8px #4DD3C780;
          color: #FFF;
          text-decoration: none;
      }
  }

  &.btn-yellow {
      background: #ffa812 0% 0% no-repeat padding-box;
      box-shadow: 0px 8px 15px #d69d3b59;
      border: none;
      &:hover {
          outline: none;
          box-shadow: 0px 4px 8px #d69d3b59;
          color: #FFF;
          text-decoration: none;
      }
  }

  &:focus {
      outline: none;
  }
}

.btn {
  &.questionreset {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translate(0, -50%);
  }
}

.label {
  display: inline-block;
  padding: 6px 12px;
  font-weight: normal;
  font-size: 80%;
  font-weight: bold;
  line-height: 1;
  color: #666;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;
  border:1px solid #666;
  margin: 0 5px !important;

  &.gray {
    color: #999999;
    border-color: #999999;
  }

  &.red {
    color: #FC6969;
    border-color: #FC6969;
  }

  &.blue {
    color: #49B2E0;
    border-color: #49B2E0;
  }

  &.green {
    color: #4DD3C7;
    border-color: #4DD3C7;
  }

  &.yellow {
    color: #ffa812;
    border-color: #ffa812;
  }
  &.purple {
    color: #e043ff;
    border-color: #e043ff;
  }  
}

.tile-title {
  &:hover {
    outline: none;
    transform: scale(0.97);
  }
}

.rdt_Table {
  border: 1px solid rgba(0,0,0,0.08);
  border-radius: 5px;
  .rdt_TableHead {
    .rdt_TableHeadRow {
      background: #4DD3C7;
      border: none;
      .rdt_TableCol {
        font-weight: bold;
        color: #FFF;
      }
      .rdt_TableCol_Sortable {
        color: #FFF;
      }
    }
  }
  .rdt_TableBody {
    .rdt_TableRow {
      border: none;
      border-top: 1px solid rgba(0,0,0,0.08);
      .rdt_TableCell {

      }
    }
  }
}

.rdt_Pagination {
  border: none !important;
}


.questionaly {
  position: relative;
  background: #f8f8f8;
  border-radius: 5px;
  padding: 10px 10px 10px 40px; 
  margin: 10px 0;
  .updownmenu {
    margin-left: 10px;
    i {
      padding: 2px;
      border-radius: 50%;
      cursor: pointer;
      transition: 0.2s;
      &:hover {
        background: #21a9e2;
        color: #FFF;
      }
    }
  }
  .newCollection {
    border: 1px solid rgba(0,0,0,0.12);
    padding: 5px;
  }
  .question-sm-button {
    margin-left: 5px;
    padding: 6px 15px;
    color: white;
    border-radius: 2px;
    cursor: pointer;
    border: none;
    &.blue {
      background: #21a9e2;
    }
    &.red {
      background: #d42020;
    }
    &.gray {
      background: #919191;
    }
    &:hover {
      color:#EFEFEF;
    }     
  }
  .question-body {
    .userArea {
      border: 3px dashed rgba(0,0,0,0.06);
      padding: 10px;
      p {
        margin: 0;
      }
    }
    font {
      left: 0;
      top: 0;
      position: absolute;
      font-size: 28px;
      font-weight: bold;
      padding: 5px;
      transform: translate(5px, 2px);
    }
    input {
      border-radius: 5px;
      color: #333;
    }
    textarea {
      border-radius: 5px;
      resize: unset;
      color: #333;
    }
  }
}

.navbar {
  background: #f8f8f8;
  border: none;
  .form-group {
    position: relative;
    input.with-icon {
      padding-left: 2.8em;
    } 
    .entypo-search {
      position: absolute;
      top:0;
      left: 3px;
      font-size: 1.8em;
    }  
  }
}

.chatimage {
  width: 50%;
  display: block;
  margin: 10px 0;
}

.label {
  &.memo {
    position: relative;
    display: inline-block;
    padding: 10px;
    cursor: pointer;
    
    .tooltip {
      position: absolute;
      bottom: 0;
      left: -310px;
      padding: 6px 10px;
      background: #FC6969;
      color: #FFF;
      border-radius: 10px;
      transition: 0.5s;
      opacity: 0;
      width: 300px;
      z-index: 9999;
      filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.2));
      pointer-events: none;
    }
      
    &.red:hover > .tooltip {
        opacity: 1;
    }
  }
}


.labels {
  display: flex;
  .label:first-child {
    overflow:visible !important;
  }
}

.tabs-vertical-env {
  ul {
    &.chat-panel {
      .chatContent {
        font-size: 1.1em;
        position: relative;
        min-width: 30%;
        .date {
          margin-top: 20px;
          font-size: 0.8em;
          text-align: right;
        }
      }
    }
  }
  .tab-content {
    min-height: 200px;
    max-height: 80%;
    overflow: auto;
  }
  .chatformwrapper {
    clear: both;
    padding: 0 20px;
    height: 20%;
    min-height: 100px;
    .chattextarea {
      width: 100%;
      margin-bottom: 10px;
      height: calc(100% - 50px);
      textarea {
        resize: none;
        height: 100%;
        border: none;
        border-radius: 10px;
      }
    }
    .chatbuttonarea {
      display: flex;
      justify-content: flex-end;
      p {
        &.caution {
          background-color: #ffefa4;
          border: 1px solid #ffd78a;
          margin: 0 10px;
          padding: 5px 10px;
          border-radius: 5px;
          color: #333;
        }
      }
    }
  }
}

.page-container {
  .sidebar-menu {
    background-image: linear-gradient(to top right, #3470df 0%, #24bd8b 100%);
  }
}
.page-container .sidebar-menu #main-menu li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.page-container .sidebar-menu .sidebar-user-info {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.page-container .sidebar-menu .logo-env > div.sidebar-collapse a, .page-container .sidebar-menu .logo-env > div.sidebar-mobile-menu a {
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.page-container .sidebar-menu .logo-env > div > a {
  color: #FFF;
}

.page-container .sidebar-menu #main-menu li ul {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.page-container .sidebar-menu #main-menu li ul > li > a {
  background-color: rgba(0, 0, 0, 0.1);
  padding-left: 40px;
}

.page-container .sidebar-menu #main-menu li ul > li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.page-container .sidebar-menu #main-menu li a {
  color: #FFF;
}

body.page-body {
  background-image: linear-gradient(to top, #3470df 0%, #24bd8b 100%) !important;
}

.login-page .login-header {
  background: rgba(0,0,0,0.1);
  .description {
    color: #FFF;
  }
}

.login-page .login-header.login-caret:after {
  border-color: rgba(0,0,0,0.1) transparent transparent transparent;
}

.login-page .login-form .form-group .input-group {
  background: rgba(0,0,0,0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  .input-group-addon {
    color: #FFF;
  }
  input.form-control {
    background: rgba(0, 0, 0, 1) !important;
  }
}

.login-page .login-form .form-group .input-group {
  padding: 0;
}

.login-page .login-form .form-group .btn-login {
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: orange;
  text-align: center;
  padding: 10px 15px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 5px 8px;
  display: inline-block;
  &:hover {
    background: orangered;
  }
}

.form-group {
  .radioButtonGroup {
    padding-top: 7px;
    label {
      margin: 0 10px 0 0;
      input {
        margin: 0;
        position: relative;
        top: 2px;
        margin-right: 3px;
      }
    }
  }
}